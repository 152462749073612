<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">

      <h5 class="pageTitle">Test</h5>
    </div>
    <hr>

    <div class="contentBody">


    </div>
  </div>
</template>

<script>


export default {
  name: 'Test',
  components: {

  },
  data(){
    return{

    }
  },
  methods:{

  },
  mounted() {
    async function f() {
      return 1;
    }

    f().then(alert);

  }
}
</script>

<style scoped>

</style>
